





















































































import KeyPad from '@/components/Elements/KeyPad/KeyPad.vue'
import Page from '@/components/Elements/Page/Page.vue'
import { HandleErrorsMixin } from '@/mixins/handlers'
import store from '@/store/index'
import { anonymousAxios } from '@/utils/blytz-axios'
import { Dict } from '@/utils/interfaces'
import { BOverlay } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { mapGetters, mapMutations } from 'vuex'

Component.registerHooks(['beforeRouteUpdate'])
@Component({
  components: {
    BOverlay,
    KeyPad,
    Page,
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({ merchant: 'currentMerchant' })
  },
  beforeRouteEnter: async (to, from, next) => {
    if (await store.getters.isAuthenticated) {
      next({
        name: 'AccountList'
      })
    } else {
      const merchantID = to.params?.merchantID
      if (merchantID) {
        await store.dispatch('fetchMerchant', merchantID)
      }

      next()
    }
  },
  methods: {
    ...mapMutations(['openDrawer'])
  }
})
export default class LoginView extends mixins(HandleErrorsMixin) {
  email = ''
  password = ''
  phone = ''
  tokenInvalid = false
  busy = false

  mounted() {
    if (this.hasToken) this.getUsername()
  }

  pinLengthCheck(value: string) {
    if (value && value.length === 4) {
      this.handleLogin()
    }
  }

  getUsername() {
    const payload = {
      id: this.$route.query.id,
      token: this.$route.query.token,
      page_size: 100
    }
    anonymousAxios
      .get(this.$endpoints.paymentLink(), { params: payload })
      .then(res => {
        this.email = res.data.email
      })
      .catch(() => {
        this.tokenInvalid = true
        this.$bvToast.toast(
          `Your pin token is invalid, please login with email and password`,
          {
            title: 'Invalid Token',
            toaster: 'b-toaster-bottom-center',
            autoHideDelay: 5000,
            appendToast: true
          }
        )

        let account: any = this.$router
        account = account['matcher'].match(this.$route.query.next).params
          .accountID

        this.$router.push({
          name: 'SendLoginLink',
          params: {
            accountID: account
          }
        })
      })
  }

  handleLogin() {
    const payload: Dict = {
      email: this.email,
      password: this.password
    }

    if (this.isPinLogin) {
      payload['token_id'] = this.$route.query.id
      payload['token'] = this.$route.query.token
    }

    this.busy = true
    this.$store
      .dispatch('login', payload)
      .then(() => {
        this.$nextTick(() => {
          if (this.$route?.query?.next) {
            const getNext = (): string => {
              if (Array.isArray(this.$route.query.next)) {
                return this.$route.query.next[0]
              } else {
                return this.$route.query.next
              }
            }
            const next: string = getNext()
            this.$router.push(next)
          } else {
            this.$router.push({
              name: 'AccountList'
            })
          }
        })
      })
      .catch(err => {
        if (err.response) {
          this.handleAPIErrors(err.response)
          this.password = ''
        } else {
          console.log('login error', err)
        }
      })
      .finally(() => (this.busy = false))
  }

  get hasToken() {
    return (
      this.$route?.query?.id && this.$route?.query?.token && !this.tokenInvalid
    )
  }

  get isPinLogin() {
    return this.hasToken && this.$route?.query?.method !== 'email'
  }

  get loginHelpDestination() {
    return this.isPinLogin ? 'ForgotPin' : 'ForgotPassword'
  }
}
