














import { Component, Prop, Vue } from 'vue-property-decorator'
import { LimitedMerchant } from '@/utils/interfaces'

import BlytzLogo from '@/components/Elements/Logo/BlytzLogo.vue'

@Component({

  components: {
    BlytzLogo
  }
})
export default class Logo extends Vue {
  @Prop({ type: Object }) readonly merchant!: LimitedMerchant

  get merchantName() {
    return this.merchant.name?.substring(0, 2)
  }
}
